import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../containers/contact';

type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Liên hệ"
        description="CVPro là công cụ giúp tạo ra CV chuyên nghiệp với hàng trăm mẫu được xây dựng sẵn hoàn toàn miễn phí và dễ dàng."
      />

      <Contact />
    </Layout>
  );
};

export default ContactPage;
